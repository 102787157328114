import React, { useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardMedia,
  useMediaQuery,
  IconButton,
  Box,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Fullscreen from "@mui/icons-material/Fullscreen";
import PostModal from "./PostModal";
import loader from "../../assets/blackLoader.svg";
import { PlayArrow } from "@mui/icons-material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { saveAs } from "file-saver";
import axios from "axios";

// Function to format the taken_at timestamp
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long", // Full month name
    day: "numeric", // Day of the month
  });
};
const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0.8) + "M"; // Format as millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K"; // Format as thousands
  }
  return num; // Return the number as is if less than 1000
};

const Post = ({
  imageUrl,
  videoUrl,
  media_type,
  mediaUrls,
  like_count,
  taken_at,
  comment_count,
}) => {
  console.log(like_count, taken_at, comment_count);
  const [open, setOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadurl, setLoadurl] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const cardRef = useRef(null);

  const handleMediaClick = (url) => {
    setMediaLoading(true);
    setOpen(true); // Open modal

    setCurrentMediaIndex(
      mediaUrls.findIndex((image) => image.imageUrl === url)
    );
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleDownload = () => {
    setDownloading(true);
    const url =
      media_type === 2
        ? videoUrl
        : `${
            process.env.REACT_APP_API_BASE_URL
          }/fetch-media?mediaUrl=${encodeURIComponent(imageUrl)}`;

    axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const extension = media_type === 2 ? "mp4" : "jpg";
        const filename = `${Date.now()}.${extension}`;
        saveAs(response.data, filename);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        console.error("Error downloading the file:", error);
      });
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        ref={cardRef}
        sx={{
          width: isMobile ? "100%" : "80%",
          padding: 0.2,
          textAlign: "center",
          backgroundColor: "#fff",
          borderRadius: "9px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
          position: "relative", // Enable absolute positioning for child elements
        }}
      >
        {loading && (
          <CardMedia
            component="img"
            height="250px"
            image={loader}
            alt="Loading"
            sx={{
              borderRadius: "8px",
              cursor: "pointer",
            }}
          />
        )}
        <CardMedia
          component="img"
          height="250px"
          image={`${
            process.env.REACT_APP_API_BASE_URL
          }/fetch-media?mediaUrl=${encodeURIComponent(imageUrl)}`}
          alt="Image"
          sx={{
            borderRadius: "8px",
            cursor: "pointer",
            display: loading ? "none" : "block",
          }}
          onClick={() => handleMediaClick(imageUrl)}
          onLoad={() => setLoading(false)} // Hide loader on load
          onError={() => setLoading(false)} // Hide loader if load fails
        />

        {/* Fullscreen icon in top-right corner */}
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={() => handleMediaClick(imageUrl)}
            aria-label="fullscreen"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <Fullscreen />
          </IconButton>
        </Box>
        {media_type === 2 && (
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -100%)",
              zIndex: 1,
              color: "#fff",
            }}
          >
            <IconButton
              onClick={() => handleMediaClick(imageUrl)}
              aria-label="fullscreen"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <PlayArrow />
            </IconButton>
          </Box>
        )}

        <CardContent sx={{ padding: "10px" }}>
          <Typography
            sx={{
              //mb: 1.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: isMobile ? "0.7rem" : "0.8rem",
              // Adjust font size as needed
            }}
            color="text.secondary"
          >
            <ThumbUpIcon
              sx={{ marginRight: 0.5, fontSize: isMobile ? "0.8rem" : "1rem" }}
            />
            {/* Adjust icon size */}
            {formatNumber(like_count)}
            <CommentIcon
              sx={{
                marginLeft: 2,
                marginRight: 0.5,
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            />
            {/* Adjust icon size */}
            {formatNumber(comment_count)}
            <AccessTimeIcon
              sx={{
                marginLeft: 2,
                marginRight: 0.5,
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            />
            {/* Adjust icon size */}
            {formatTimestamp(taken_at)} {/* Format and display the date */}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "center", paddingTop: "0px" }}>
          <Button
            variant="contained"
            onClick={handleDownload}
            aria-label="download"
            disabled={downloading}
            sx={{
              backgroundColor: downloading ? "#f0f0f0" : "primary.main", // Custom disabled background color
              color: downloading ? "#b0b0b0" : "white", // Custom disabled text color
              "&:hover": {
                backgroundColor: downloading ? "#f0f0f0" : "primary.dark", // Hover effect for enabled button
              },
            }}
          >
            {downloading ? "Downloading..." : "Download Now"}
            {!downloading && <DownloadIcon />}
          </Button>
        </CardActions>
      </Card>
      {/* Modal for displaying image or video */}
      <PostModal
        mediaUrls={mediaUrls}
        open={open}
        setOpen={setOpen}
        handleDownload={handleDownload}
        currentMediaIndex={currentMediaIndex}
        setCurrentMediaIndex={setCurrentMediaIndex}
        mediaLoading={mediaLoading}
        setMediaLoading={setMediaLoading}
      />
    </div>
  );
};

export default Post;
