import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import axios from "axios";
import ProfileSec from "../components/ProfileSec/ProfileSec";
import PostsTabs from "../components/ProfileSec/PostsTabs";
import HeroSec from "../components/HeroSec/HeroSec";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserInfo,
  fetchUserPosts,
  resetUserInfo,
  resetUserPosts,
  resetUserReels,
  resetUserStories,
} from "../redux/slice";
import Loader from "../components/Common/Loader";

const Home = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const loaderRef = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.instaData.userInfo);
  const userPosts = useSelector((state) => state.instaData.userPosts);
  const userInfoLoading = useSelector(
    (state) => state.instaData.userInfoLoading
  );
  const userPostsloading = useSelector(
    (state) => state.instaData.userPostsLoading
  );

  const handleInputChange = (event) => {
    setUsername(event.target.value);
  };

  useEffect(() => {
    if (userInfo && loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userInfo]);

  const handleSearch = async () => {
    // dispatch(resetUserInfo());
    if (!username) {
      setError("Username cannot be empty.");
      return;
    }

    dispatch(resetUserInfo()); // Optional: reset previous user info
    dispatch(resetUserPosts()); // Clear previous posts
    dispatch(resetUserReels()); // Clear previous reels
    dispatch(resetUserStories()); // Clear previous stories

    dispatch(fetchUserInfo(username));
    dispatch(fetchUserPosts({ username, count: 5, next_max_id: "" }));
  };
  return (
    <Box sx={{ minHeight: "100vh", minWidth: "100%", overflow: "hidden" }}>
      <HeroSec
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        username={username}
        setUsername={setUsername}
        userInfo={userInfo}
        error={error}
        setError={setError}
      />

      {userInfoLoading ? (
        // Show loading spinner when loading state is true

        <Loader /> /* Loading Spinner */
      ) : (
        userInfo && (
          <Container
            ref={loaderRef}
            id="profile"
            sx={{
              // padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding for different screen sizes
              backgroundColor: "#1976d2", // Background color
              minWidth: "100%", // Full width of the viewport
              minHeight: "100vh", // Full viewport height
              display: "flex", // Flexbox layout for container
              flexDirection: "column",
              justifyContent: "space-between", // Space out the content vertically
              maxWidth: { xs: "100vw", md: "90vw" }, // Full width on small screens, 90% on larger screens
              margin: "0 auto", // Center the content horizontally
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  color: "whitesmoke",
                  textAlign: "center",
                  marginBottom: "16px",
                  fontWeight: "500",
                }}
              >
                Search Result
              </Typography>
              <ProfileSec userInfo={userInfo} />
            </Box>

            <PostsTabs />
          </Container>
        )
      )}
    </Box>
  );
};

export default Home;
