import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0",
        backgroundColor: "#333",
        color: "white",
        position: "relative",
        bottom: 0,
        width: "100%",
      }}
    >
      <Typography sx={{ fontSize: "0.6rem" }}>
        © Instagram Story Viewer. {new Date().getFullYear()}. AnonyIG is not
        affiliated with Instagram™. We do not host any Instagram content. All
        rights belong to their respective owners.
      </Typography>
    </Box>
  );
};

export default Footer;
