import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/api";

export const fetchUserInfo = createAsyncThunk(
  "fetchUserInfo",
  async (username) => {
    try {
      const response = await api.get(`/user_info/${username}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserPosts = createAsyncThunk(
  "fetchUserPosts",
  async ({ username, count, next_max_id }) => {
    try {
      const response = await api.get(
        `/user_posts/${username}/${count}/${next_max_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//reels
export const fetchUserReels = createAsyncThunk(
  "fetchUserReels",
  async ({ username, max_id }) => {
    try {
      const response = await api.get(`/user_reels/${username}/${max_id || ""}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//story
export const fetchUserStories = createAsyncThunk(
  "fetchUserStories",
  async ({ username }) => {
    try {
      const response = await api.get(`/user_stories/${username}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const instaSlice = createSlice({
  name: "instaData",
  initialState: {
    userInfo: null,
    userInfoLoading: false,
    userPosts: null,
    userPostsLoading: false,
    userReels: null,
    userReelsLoading: false,
    userStories: null,
    userStoriesLoading: false,
  },
  reducers: {
    resetUserInfo: (state) => {
      state.userInfo = null;
    },
    resetUserPosts: (state) => {
      state.userPosts = null;
    },
    resetUserReels: (state) => {
      state.userReels = null;
    },
    resetUserStories: (state) => {
      state.userStories = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.userInfoLoading = false;
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.userInfoLoading = false;
      })
      .addCase(fetchUserPosts.pending, (state) => {
        state.userPostsLoading = true;
      })
      .addCase(fetchUserPosts.fulfilled, (state, action) => {
        state.userPosts = action.payload;
        state.userPostsLoading = false;
      })
      .addCase(fetchUserPosts.rejected, (state) => {
        state.userPostsLoading = false;
      })
      .addCase(fetchUserReels.pending, (state) => {
        state.userReelsLoading = true;
      })
      .addCase(fetchUserReels.fulfilled, (state, action) => {
        state.userReels = action.payload;
        state.userReelsLoading = false;
      })
      .addCase(fetchUserReels.rejected, (state) => {
        state.userReelsLoading = false;
      })
      .addCase(fetchUserStories.pending, (state) => {
        state.userStoriesLoading = true;
      })
      .addCase(fetchUserStories.fulfilled, (state, action) => {
        state.userStories = action.payload;
        state.userStoriesLoading = false;
      })
      .addCase(fetchUserStories.rejected, (state) => {
        state.userStoriesLoading = false;
      });
  },
});

export const {
  resetUserInfo,
  resetUserPosts,
  resetUserReels,
  resetUserStories,
} = instaSlice.actions;
export default instaSlice.reducer;
