import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "../Common/Loader";

const PostModal = ({
  currentMediaIndex,
  setCurrentMediaIndex,
  mediaUrls,
  handleDownload,
  open,
  setOpen,
  setMediaLoading,
  mediaLoading,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    setOpen(false);
  };
  const handlePrevious = () => {
    setMediaLoading(true);
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === 0 ? mediaUrls?.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setMediaLoading(true);
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === mediaUrls?.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleLoading = () => {
    setMediaLoading(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, handlePrevious, handleNext]);

  const iconButtonStyle = {
    width: isMobile ? 35 : 40,
    height: isMobile ? 35 : 40,
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "100%",
          position: "static",
          borderRadius: 0,
        },
        "& .MuiDialog-container": {
          backgroundColor: "rgb(0, 0, 0, 0.8)",
          width: "100%",
        },
        display: "flex",
        justifyContent: "center",
      }}
    >
      {mediaUrls?.[currentMediaIndex]?.media_type == "1" ? (
        <>
          {mediaLoading && <Loader />}
          <img
            onLoad={handleLoading}
            src={`${
              process.env.REACT_APP_API_BASE_URL
            }/fetch-media?mediaUrl=${encodeURIComponent(
              mediaUrls?.[currentMediaIndex]?.imageUrl
            )}`}
            alt="Image"
            style={{
              display: mediaLoading ? "none" : "block",
              maxWidth: "100%", // Ensures the image doesn't exceed the container width
              maxHeight: "70vh", // Limits the height to 80% of the viewport height
              width: "auto", // Keeps the aspect ratio
              height: "auto", // Keeps the aspect ratio
              objectFit: "contain",
              background: "transparent",
            }}
          />
        </>
      ) : (
        <>
          {mediaLoading && <Loader />}
          <video
            // preload="auto"
            controls
            onLoadedData={handleLoading}
            style={{
              display: mediaLoading ? "none" : "block",
              maxWidth: "100%", // Ensures the image doesn't exceed the container width
              maxHeight: "70vh", // Limits the height to 80% of the viewport height
              width: "auto", // Keeps the aspect ratio
              height: "auto", // Keeps the aspect ratio
              objectFit: "contain",
              background: "transparent",
            }}
            autoPlay
          >
            <source
              // src={`${
              //   process.env.REACT_APP_API_BASE_URL
              // }/fetch-media?mediaUrl=${encodeURIComponent(
              //   mediaUrls?.[currentMediaIndex]?.videoUrl
              // )}`}
              src={mediaUrls?.[currentMediaIndex]?.videoUrl}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </>
      )}
      {/* </div> */}

      {/* Previous Button */}
      {currentMediaIndex > 0 ? (
        <IconButton
          onClick={handlePrevious}
          sx={{
            ...iconButtonStyle,
            position: "absolute",
            left: 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 10000,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          sx={{
            ...iconButtonStyle,
            position: "absolute",
            left: 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 10000,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
            cursor: "not-allowed",
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      )}

      {/* Next Button */}

      {currentMediaIndex < mediaUrls?.length - 1 ? (
        <IconButton
          onClick={handleNext}
          // disabled={currentMediaIndex === mediaUrls.length - 1} // Disable if at the last media
          sx={{
            ...iconButtonStyle,
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 10000,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
        >
          <ArrowForwardIcon fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          // disabled={currentMediaIndex === mediaUrls.length - 1} // Disable if at the last media
          sx={{
            ...iconButtonStyle,
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 10000,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
            cursor: "not-allowed",
          }}
        >
          <ArrowForwardIcon fontSize="small" />
        </IconButton>
      )}

      {/* Download Button inside modal */}
      <IconButton
        onClick={handleDownload}
        size="small"
        sx={{
          ...iconButtonStyle,
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 10000,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
      >
        <DownloadIcon fontSize="small" />
      </IconButton>

      {/* Close Button inside modal */}
      <IconButton
        onClick={handleClose}
        size="small"
        sx={{
          ...iconButtonStyle,
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 10000,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

export default PostModal;
