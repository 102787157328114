import React, { useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardMedia,
  useMediaQuery,
  IconButton,
  Box,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Fullscreen from "@mui/icons-material/Fullscreen";
import loader from "../../assets/blackLoader.svg";
import { PlayArrow } from "@mui/icons-material";
import StoryModal from "./StoryModal";

// Function to format the timestamp into a readable date format
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// Function to format numbers into a human-readable format (e.g., 1.5K, 2.3M)
const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num;
};

const Stories = ({ videoUrl, imageUrl, media_type, storiesMediaUrls }) => {
  console.log(
    "Reels Props - imageUrl:",
    `${
      process.env.REACT_APP_API_BASE_URL
    }/fetch-media?mediaUrl=${encodeURIComponent(imageUrl)}`
  );
  console.log("Reels Props - media_type:", media_type);
  console.log("Reels Props - reelsMediaUrls:", storiesMediaUrls);

  const [open, setOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const cardRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleMediaClick = (url) => {
    setMediaLoading(true);
    setOpen(true); // Open modal
    setCurrentMediaIndex(
      storiesMediaUrls.findIndex((video) => video.videoUrl === url)
    );
  };

  const handleDownload = async () => {
    setDownloading(true);
    const url = media_type === 2 ? videoUrl : "";
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a URL for the Blob and download it
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = url.includes(".mp4") ? "video.mp4" : "file"; // Set filename

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl); // Clean up
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("There was an error downloading the media. Please try again.");
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        ref={cardRef}
        sx={{
          width: isMobile ? "100%" : "80%",
          padding: 0.2,
          textAlign: "center",
          backgroundColor: "#fff",
          borderRadius: "9px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
          position: "relative",
        }}
      >
        {/* Loader image while loading */}
        {loading && (
          <CardMedia
            component="img"
            height="250px"
            image={loader}
            alt="Loading"
            sx={{
              borderRadius: "8px",
              cursor: "pointer",
            }}
          />
        )}
        <CardMedia
          component="img"
          height="250px"
          image={`${
            process.env.REACT_APP_API_BASE_URL
          }/fetch-media?mediaUrl=${encodeURIComponent(imageUrl)}`}
          alt="Image"
          sx={{
            borderRadius: "8px",
            cursor: "pointer",
            display: loading ? "none" : "block",
          }}
          onClick={() => handleMediaClick(imageUrl)}
          onLoad={() => setLoading(false)} // Hide loader on load
          onError={() => setLoading(false)} // Hide loader if load fails
        />

        {/* Fullscreen Icon */}
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={() => handleMediaClick(videoUrl)}
            aria-label="fullscreen"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <Fullscreen />
          </IconButton>
        </Box>

        {/* Play icon overlay for video */}
        {media_type === 2 && (
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -100%)",
              zIndex: 1,
              color: "#fff",
            }}
          >
            <IconButton
              onClick={() => handleMediaClick(videoUrl)}
              aria-label="play"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <PlayArrow />
            </IconButton>
          </Box>
        )}

        <CardContent sx={{ padding: "10px" }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: isMobile ? "0.7rem" : "0.8rem",
            }}
            color="text.secondary"
          >
            Story
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "center", paddingTop: "0px" }}>
          <Button
            variant="contained"
            onClick={handleDownload}
            aria-label="download"
            disabled={downloading}
            sx={{
              backgroundColor: downloading ? "#f0f0f0" : "primary.main",
              color: downloading ? "#b0b0b0" : "white",
              "&:hover": {
                backgroundColor: downloading ? "#f0f0f0" : "primary.dark",
              },
            }}
          >
            {downloading ? "Downloading..." : "Download Now"}
            {!downloading && <DownloadIcon />}
          </Button>
        </CardActions>
      </Card>

      {/* Modal for full-screen view */}
      <StoryModal
        storiesMediaUrls={storiesMediaUrls}
        open={open}
        setOpen={setOpen}
        handleDownload={handleDownload}
        currentMediaIndex={currentMediaIndex}
        setCurrentMediaIndex={setCurrentMediaIndex}
        mediaLoading={mediaLoading}
        setMediaLoading={setMediaLoading}
      />
    </div>
  );
};

export default Stories;
