import React, { useEffect, useRef } from "react";
import { Dialog, IconButton, useMediaQuery } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "../Common/Loader";

const ReelModal = ({
  currentMediaIndex,
  setCurrentMediaIndex,
  reelsMediaUrls,
  handleDownload,
  open,
  setOpen,
  setMediaLoading,
  mediaLoading,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const videoRef = useRef(null); // Create a ref for the video element

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = () => {
    setMediaLoading(true);
    if (videoRef.current) videoRef.current.pause(); // Pause video when switching media
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === 0 ? reelsMediaUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setMediaLoading(true);
    if (videoRef.current) videoRef.current.pause(); // Pause video when switching media
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === reelsMediaUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleLoading = () => {
    setMediaLoading(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  useEffect(() => {
    if (
      reelsMediaUrls?.[currentMediaIndex]?.media_type !== "1" &&
      videoRef.current
    ) {
      videoRef.current.load(); // Reload the video for the new source
    }
  }, [currentMediaIndex]);

  const iconButtonStyle = {
    width: isMobile ? 35 : 40,
    height: isMobile ? 35 : 40,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "100%",
          position: "static",
          borderRadius: 0,
        },
        "& .MuiDialog-container": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          width: "100%",
        },
        display: "flex",
        justifyContent: "center",
      }}
    >
      {reelsMediaUrls?.[currentMediaIndex]?.media_type === "1" ? (
        <>
          {mediaLoading && <Loader />}
          <img
            onLoad={handleLoading}
            src={`${
              process.env.REACT_APP_API_BASE_URL
            }/fetch-media?mediaUrl=${encodeURIComponent(
              reelsMediaUrls[currentMediaIndex]?.imageUrl
            )}`}
            alt="Image"
            style={{
              display: mediaLoading ? "none" : "block",
              maxWidth: "100%",
              maxHeight: "70vh",
              width: "auto",
              height: "auto",
              objectFit: "contain",
              background: "transparent",
            }}
          />
        </>
      ) : (
        <>
          {mediaLoading && <Loader />}
          <video
            ref={videoRef} // Set the ref to the video element
            controls
            onLoadedData={handleLoading}
            style={{
              display: mediaLoading ? "none" : "block",
              maxWidth: "100%",
              maxHeight: "70vh",
              width: "auto",
              height: "auto",
              objectFit: "contain",
              background: "transparent",
            }}
            autoPlay
          >
            <source
              src={reelsMediaUrls[currentMediaIndex]?.videoUrl}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </>
      )}

      {/* Previous Button */}
      <IconButton
        onClick={handlePrevious}
        disabled={currentMediaIndex === 0} // Disable if at the first media
        sx={{
          ...iconButtonStyle,
          position: "absolute",
          left: 10,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10000,
          backgroundColor:
            currentMediaIndex === 0
              ? "rgba(255, 255, 255, 0.3)"
              : "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor:
              currentMediaIndex === 0
                ? "rgba(255, 255, 255, 0.3)"
                : "rgba(255, 255, 255, 0.9)",
          },
          cursor: currentMediaIndex === 0 ? "not-allowed" : "pointer",
        }}
      >
        <ArrowBackIcon fontSize="small" />
      </IconButton>

      {/* Next Button */}
      <IconButton
        onClick={handleNext}
        disabled={currentMediaIndex === reelsMediaUrls.length - 1} // Disable if at the last media
        sx={{
          ...iconButtonStyle,
          position: "absolute",
          right: 10,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 10000,
          backgroundColor:
            currentMediaIndex === reelsMediaUrls.length - 1
              ? "rgba(255, 255, 255, 0.3)"
              : "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor:
              currentMediaIndex === reelsMediaUrls.length - 1
                ? "rgba(255, 255, 255, 0.3)"
                : "rgba(255, 255, 255, 0.9)",
          },
          cursor:
            currentMediaIndex === reelsMediaUrls.length - 1
              ? "not-allowed"
              : "pointer",
        }}
      >
        <ArrowForwardIcon fontSize="small" />
      </IconButton>

      {/* Download Button inside modal */}
      <IconButton
        onClick={handleDownload}
        size="small"
        sx={{
          ...iconButtonStyle,
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 10000,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
      >
        <DownloadIcon fontSize="small" />
      </IconButton>

      {/* Close Button inside modal */}
      <IconButton
        onClick={handleClose}
        size="small"
        sx={{
          ...iconButtonStyle,
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 10000,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Dialog>
  );
};

export default ReelModal;
