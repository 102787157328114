import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  Fab,
  Avatar,
} from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import Post from "./Post";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserPosts,
  fetchUserReels,
  fetchUserStories,
} from "../../redux/slice";
import Reels from "./Reels";
import Stories from "./Stories";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%", height: "100%" }}
    >
      {value === index && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const PostsTabs = () => {
  const [value, setValue] = useState(0);
  const userPosts = useSelector((state) => state.instaData.userPosts);
  const userReels = useSelector((state) => state.instaData.userReels);
  const userStories = useSelector((state) => state.instaData.userStories);

  const max_id = useSelector(
    (state) => state?.instaData?.userReels?.data?.paging_info?.max_id
  ); //reels
  const next_max_id = useSelector(
    (state) => state?.instaData?.userPosts?.data?.next_max_id
  ); //posts
  const username = useSelector(
    (state) => state?.instaData?.userPosts?.data?.user?.username
  );

  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showScroll, setShowScroll] = useState(false);

  const [mediaUrls, setMediaUrls] = useState([]);
  const [reelsMediaUrls, setReelsMediaUrls] = useState([]);
  const [storiesMediaUrls, setStoriesMediaUrls] = useState([]);

  const handleLoadPosts = () => {
    dispatch(
      fetchUserPosts({
        username,
        count: 5,
        next_max_id,
      })
    );
  };

  const handleLoadReels = () => {
    if (username && !userReels) {
      dispatch(fetchUserReels({ username, max_id }));
    } else {
      console.error("Username is not defined for reels");
    }
  };
  const handleLoadStories = () => {
    if (username && !userStories) {
      dispatch(fetchUserStories({ username }));
    } else {
      console.error("Username is not defined for stories");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      // When Reels tab is clicked
      handleLoadReels(); // Load reels
    }
    if (newValue === 2) {
      // When Reels tab is clicked
      handleLoadStories(); // Load reels
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  //for posts
  useEffect(() => {
    if (userPosts?.data?.items) {
      const newMediaUrls = userPosts.data.items
        .map((item) => {
          if (item.media_type === 8) {
            return item.carousel_media.map((carouselItem) => ({
              imageUrl: carouselItem.image_versions2?.candidates[0].url || null,
              videoUrl: carouselItem.video_versions?.[0]?.url || null,
              media_type: carouselItem.media_type,
              like_count: item?.like_count,
              taken_at: item?.taken_at,
              comment_count: item?.comment_count,
            }));
          } else if (item.media_type === 1) {
            return {
              imageUrl: item.image_versions2?.candidates[0].url || null,
              videoUrl: null,
              media_type: item.media_type,
              like_count: item?.like_count,
              taken_at: item?.taken_at,
              comment_count: item?.comment_count,
            };
          } else if (item.media_type === 2) {
            return {
              imageUrl: item.image_versions2?.candidates[0].url || null,
              videoUrl: item.video_versions?.[0]?.url || null,
              media_type: item.media_type,
              like_count: item?.like_count,
              taken_at: item?.taken_at,
              comment_count: item?.comment_count,
            };
          }
          return null;
        })
        .flat()
        .filter(Boolean);

      setMediaUrls((prevUrls) =>
        next_max_id ? [...prevUrls, ...newMediaUrls] : newMediaUrls
      );
    }
  }, [userPosts, next_max_id]);
  // for reels
  useEffect(() => {
    if (userReels?.data?.items) {
      const newReelsUrls = userReels.data.items
        .map((item) => {
          if (item.media.media_type === 2) {
            return {
              videoUrl: item?.media?.video_versions?.[0].url || null,
              imageUrl:
                item?.media?.image_versions2?.candidates?.[0].url || null,
              media_type: item?.media?.media_type,
            };
          }
          return null;
        })
        .filter(Boolean);

      setReelsMediaUrls((prevUrls) =>
        userReels.data.paging_info.max_id
          ? [...prevUrls, ...newReelsUrls]
          : newReelsUrls
      );
    }
  }, [userReels, max_id]);
  //for stories
  useEffect(() => {
    if (userStories?.data?.reel?.items) {
      const newStoriesUrls = userStories.data.reel.items
        .map((item) => {
          return {
            videoUrl: item?.video_versions?.[0].url || null,
            imageUrl: item?.image_versions2?.candidates?.[0].url || null,
            media_type: item?.media_type,
          };
          return null;
        })
        .filter(Boolean);
      setStoriesMediaUrls(newStoriesUrls);
      setStoriesMediaUrls((prevUrls) =>
        userStories.data.paging_info?.max_id
          ? [...prevUrls, ...newStoriesUrls]
          : newStoriesUrls
      );
    }
  }, [userStories]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="profile tabs"
        centered
        textColor="inherit"
        indicatorColor="primary"
      >
        <Tab label="POSTS" sx={{ color: "white" }} />
        <Tab label="REELS" sx={{ color: "white" }} />
        <Tab label="STORIES" sx={{ color: "white" }} />
        <Tab label="HIGHLIGHTS" sx={{ color: "white" }} />
      </Tabs>

      {/* posts */}
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {mediaUrls.map((media, index) => (
            <Box
              key={index}
              sx={{ width: isMobile ? "100%" : "calc(33.33% - 10px)" }}
            >
              <Post
                imageUrl={media.imageUrl}
                videoUrl={media.videoUrl}
                media_type={media.media_type}
                mediaUrls={mediaUrls}
                like_count={media.like_count}
                taken_at={media.taken_at}
                comment_count={media.comment_count}
              />
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          color="success"
          sx={{ width: "100%", marginTop: "20px" }}
          onClick={handleLoadPosts}
        >
          Load More Posts
        </Button>
      </TabPanel>

      {/* reels */}
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {reelsMediaUrls.map((media, index) => (
            <Box
              key={index}
              sx={{ width: isMobile ? "100%" : "calc(33.33% - 10px)" }}
            >
              <Reels
                videoUrl={media.videoUrl}
                media_type={media.media_type}
                reelsMediaUrls={reelsMediaUrls}
                imageUrl={media.imageUrl}
              />
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          color="success"
          sx={{ width: "100%", marginTop: "20px" }}
          onClick={handleLoadReels}
        >
          Load More Reels
        </Button>
      </TabPanel>
      {/* stories */}
      <TabPanel value={value} index={2}>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {storiesMediaUrls.map((media, index) => (
            <Box
              key={index}
              // sx={{ width: isMobile ? "100%" : "calc(33.33% - 10px)" }}
            >
              <Stories
                videoUrl={media.videoUrl}
                media_type={media.media_type}
                storiesMediaUrls={storiesMediaUrls}
                imageUrl={media.imageUrl}
              />
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          color="success"
          sx={{ width: "100%", marginTop: "20px" }}
          onClick={handleLoadStories}
        >
          Load More Stories
        </Button>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 100, height: 100 }}
          />
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 100, height: 100 }}
          />
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 100, height: 100 }}
          />
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 100, height: 100 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            marginTop: "10px",
            height: "100vh",
          }}
        >
          HIGHLIGHTS
        </Box>
      </TabPanel>

      {showScroll && (
        <Fab
          color="primary"
          size="small"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "10px",
            right: "0px",
            zIndex: 1000,
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <ArrowUpward />
        </Fab>
      )}
    </Box>
  );
};

export default PostsTabs;
